@use 'styles/variables' as var;

.button {
  max-width: 400px;
}

.container-grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 60px 0;
  align-items: center;
}

.text {
  text-align: center;
}

@media only screen and (min-width: var.$breakpoint-md) {
  .container-grid {
    min-height: calc(100vh - 740px);
  }
}